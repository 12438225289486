a {
  color: #a2a8b9;
}

body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

@media (max-width: 768px) {
  .ascii-art {
    font-size: 0.5em; /* Adjust this value as needed */
    line-height: 1;
    white-space: pre;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
  
  /* Add more specific styles for mobile devices */
}

input {
  border: none;
  background-color: transparent;
  display: inline-block;
  outline: none;
  min-width: auto;
}

p {
  color: #8c93a8;
  font-family: monospace;
}

.wrapper {
  background-color: "#45364B";
  min-width: "100vw";
  min-height: "100vh";
}

.ui {
  position: absolute;
  bottom: 0;
  left: 0;
}
